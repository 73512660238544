import React from "react"
import styled from "@emotion/styled"
import { a, useSpring } from "react-spring"
import { useInView } from "react-intersection-observer"
import { mqHandheld, mqTablet, mqTabletHandheld } from "components/index.css"
import { H1, H2, P, Section as Sect } from "components/typography"
import SEO from "../components/seo"
import S from "components/sticker"
import Quote from "components/quote"

const triggerOnce = true

const TranquilityPage = () => {
  const [firstRef, firstInView] = useInView({ triggerOnce })
  const [secondRef, secondInView] = useInView({ triggerOnce })
  const [stickerRef, stickerInView] = useInView({ triggerOnce, threshold: 0.5 })

  const firstProps = useSpring({
    opacity: firstInView ? 1 : 0,
    transform: firstInView ? `translateX(0) ` : ` translateX(-20%)`,
  })

  const secondProps = useSpring({
    opacity: secondInView ? 1 : 0,
    transform: secondInView ? `translateX(0) ` : ` translateX(20%)`,
  })

  return (
    <>
      <SEO title="Tranquilidad" />
      <H1>tranquilidad</H1>
      <Quote>
        <p>
          El Compliance es una herramienta m&aacute;s de protecci&oacute;n de la
          empresa, como lo son Seguros, Controles de calidad, Sistemas de
          seguridad&#8230;
        </p>
        <p>
          M&ordf; &Aacute;ngeles Horta <br />
          Directora de Compliance
        </p>
      </Quote>
      <Section>
        <Copy style={firstProps}>
          <H2>Qu&eacute; aportan los planes de compliance a tu empresa</H2>
          <Ul ref={firstRef}>
            <Li>Posicionan por delante de la competencia.</Li>
            <Li>Otorgan un valor a&ntilde;adido.</Li>
            <Li>Optimizan la imagen percibida por el cliente.</Li>
            <Li>Aportan seguridad y tranquilidad.</Li>
            <Li>
              Aten&uacute;an los riesgos desde que el &oacute;rgano de
              administraci&oacute;n ha adoptado el acuerdo de su
              implantaci&oacute;n.
            </Li>
            <Li>Mejoran los procesos y controles internos</Li>
          </Ul>
        </Copy>
      </Section>
      <Section marginTop={0}>
        <StickerWrapper ref={stickerRef}>
          <Sticker side="left" inView={stickerInView}>
            <P>&amp;</P>
            <P>estamos</P>
            <P>a tu lado</P>
            <P>cuando nos</P>
            <P>necesites</P>
          </Sticker>
        </StickerWrapper>
        <Copy style={secondProps}>
          <H2>Por qu&eacute; son necesarios en tu empresa</H2>
          <Ul ref={secondRef}>
            <Li>Protegen de posibles riesgos legales y penales.</Li>
            <Li>
              Salvaguardan tu empresa de posibles sanciones derivadas de estos
              riesgos.
            </Li>
            <Li>
              Tener acceso a realizar contratos con la Administraci&oacute;n
              p&uacute;blica y empresas privadas. (cada vez son m&aacute;s las
              empresas que solicitan a sus proveedores/clientes tener implantado
              un sistema de compliance)
            </Li>
          </Ul>
        </Copy>
      </Section>
    </>
  )
}

export default TranquilityPage

const Section = styled(Sect)({
  overflow: `hidden`,
})

const Copy = styled(a.div)({
  flex: `1 1 70%`,
  maxWidth: 650,
  [mqTabletHandheld]: {
    flex: `1 0 100%`,
  },
})

const Sticker = styled(S)(({ side }) => ({
  background: `rgba(164, 0, 198, 1)`,
  marginLeft: 75,
  "> div": {
    paddingBottom: 15,
  },
}))

const StickerWrapper = styled.div({
  flex: 1,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
})

const Ul = styled.ul({
  listStyleType: `none`,
  fontFamily: `RecoletaLight`,
  fontSize: 31,
  margin: 0,
  paddingLeft: `1em`,
  display: `inline-block`,

  [mqTablet]: {
    fontSize: 42,
    lineHeight: `50px`,
    marginBottom: 40,
  },
  [mqHandheld]: {
    fontSize: 24,
    lineHeight: `29px`,
    marginBottom: 25,
  },
})

const Li = styled.li({
  "&:not(:first-of-type)": {
    marginTop: 15,
  },
  "&:before": {
    content: `'-'`,
    display: `inline-block`,
    width: `1em`,
    marginLeft: `-1em`,
  },
})
